<template>
    <div>
        <aside :class="`${is_expanded && 'is-expanded'} ${isMobile && 'mobile'}`">
            <div class="logo">
                <img :src="logoURL" alt="Domus" style="width: 30px; height: 30px;">
            </div>

            <div class="menu-toggle-wrap">
                <button class="menu-toggle" @click="ToggleMenu()">
                    <span class="material-icons">keyboard_double_arrow_right</span>
                </button>
            </div>

            <h3>Menu</h3>
            <div class="menu">
                <router-link class="button" to="/">
                    <span class="material-icons">home</span>
                    <span class="text">Home</span>
                </router-link>
                <router-link class="button" to="/administrators" v-if="user && user.role == 'superadmin'">
                    <span class="material-icons">admin_panel_settings</span>
                    <span class="text">Administradores</span>
                </router-link>
                <router-link class="button" to="/providers">
                    <span class="material-icons">person_4</span>
                    <span class="text">Prestadores</span>
                </router-link>
                <router-link class="button" to="/patients">
                    <span class="material-icons">personal_injury</span>
                    <span class="text">Pacientes</span>
                </router-link>
                <router-link class="button" to="/clinical_record">
                    <span class="material-icons">description</span>
                    <span class="text">Ficha Clínica</span>
                </router-link>
            </div>

            <div class="sub_menu">
                <button class="w100 button-perfil" @click="ProfileOptions">
                    <span class="material-icons gear-icon">settings</span>
                    <span class="text" v-if="user">{{ user.first_name }} {{ user.last_name }}</span>
                    <span class="role" v-if="user">{{ dictRole[user.role] }}</span>
                </button>
            </div>
        </aside>

        <nav class="mobile-nav" v-if="isMobile">
            <div class="nav-content">
                <div class="primary-icons">
                    <router-link class="nav-button" to="/">
                        <span class="material-icons">home</span>
                        <span class="text">Home</span>
                    </router-link>
                    <router-link class="nav-button" to="/patients">
                        <span class="material-icons">personal_injury</span>
                        <span class="text">Pacientes</span>
                    </router-link>
                    <router-link class="nav-button" to="/clinical_record">
                        <span class="material-icons">description</span>
                        <span class="text">Ficha</span>
                    </router-link>
                    <button class="nav-button" @click="toggleSidebar">
                        <span class="material-icons">menu</span>
                        <span class="text">Más</span>
                    </button>
                </div>
            </div>
        </nav>

        <div class="mobile-overlay" v-if="isMobile && is_expanded" @click="closeSidebar"></div>

        <Popover ref="options" class="popover">
            <div class="flex popover-content flex-col gap-4 w-[20rem]">
                <button class="button w100">Perfil</button>
                <hr>
                <button class="button w100" @click="Logout()">Cerrar sesión</button>
            </div>
        </Popover>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import logoURL from '../assets/logo.png'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore();
const user = computed(() => authStore.user);
const options = ref();
const router = useRouter();
const isMobile = ref(false);

const dictRole = ref({
    provider: 'Prestador',
    patient: 'Paciente',
    admin: 'Administrador',
    superadmin: 'Super Administrador',
});

const is_expanded = ref(localStorage.getItem("is_expanded") === "true")

const checkMobile = () => {
    isMobile.value = window.innerWidth <= 1024;
    if (!isMobile.value) {
        is_expanded.value = localStorage.getItem("is_expanded") === "true";
    }
}

const toggleSidebar = () => {
    is_expanded.value = true;
}

const closeSidebar = () => {
    if (isMobile.value) {
        is_expanded.value = false;
    }
}

onMounted(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkMobile);
});

const ToggleMenu = () => {
    is_expanded.value = !is_expanded.value
    if (!isMobile.value) {
        localStorage.setItem("is_expanded", is_expanded.value)
    }
}

const ProfileOptions = (event) => {
    options.value.toggle(event);
}

const Logout = async () => {
    try {
        await authStore.logout();
        router.push('/login');
    } catch (error) {
        console.log(error);
    }
}
</script>

<style lang="scss" scoped>
aside {
    display: flex;
    flex-direction: column;

    background-color: var(--dark);
    color: var(--light);

    width: calc(2rem + 32px);
    overflow: hidden;
    min-height: 100vh;
    padding: 1rem;

    transition: 0.2s ease-out;

    &.mobile {
        position: fixed;
        left: -100%;
        top: 0;
        height: 100vh;
        bottom: 0;
        z-index: 1000;
        width: 80%; // Ancho del sidebar en móvil
        max-width: 300px;

        &.is-expanded {
            left: 0;
        }
    }

    
    .logo {
        img {
            width: 30px;
            height: 30px;
            margin-bottom: 20px;
            transition: width 0.3s ease, height 0.3s ease;
        }
    }

    .menu-toggle-wrap {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;

        position: relative;
        top: 0;
        transition: 0.2s ease-in-out;

        .menu-toggle {
            transition: 0.2s ease-in-out;

            .material-icons {
                font-size: 2rem;
                color: var(--light);
                transition: 0.2s ease-out;
            }

            &:hover {
                .material-icons {
                    color: var(--primary);
                    transform: translateX(0.5rem);
                }
            }
        }
    }

    h3,
    .button .text {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        max-width: 0;
        overflow: hidden;
        white-space: nowrap;
    }

    h3 {
        color: white;
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    .sub_menu {
        position: fixed;
        bottom: 0;

        .button-perfil {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.5rem 1rem;
            color: var(--light);
        }

        .text {
            color: var(--light);
            font-size: 1rem;
        }

        .role {
            color: var(--light);
            font-size: 0.9rem;
        }

        .button {
            display: flex;
            align-items: center;
            text-decoration: none;

            transition: 0.2s ease-in-out;
            padding: 0.5rem 1rem;

            .material-icons {
                font-size: 1.5rem;
                color: var(--light);
                transition: 0.2s ease-in-out;
            }

            .text {
                color: var(--light);
                transition: 0.2s ease-in-out;
            }

            &:hover {
                background-color: var(--dark-alt);

                .material-icons:not(.router-link-exact-active .material-icons) {
                    transform: rotate(-10deg);
                }

                .material-icons,
                .text {
                    color: var(--primary);
                }
            }

            &.router-link-exact-active {
                background-color: var(--dark-alt);
                border-right: 5px solid var(--primary);

                .material-icons,
                .text {
                    color: var(--primary);
                }
            }
        }
    }

    .menu {
        margin: 0 -1rem;

        .button-perfil {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0.5rem 1rem;
            color: var(--light);
        }

        .text {
            color: var(--light);
            font-size: 1rem;
        }

        .role {
            color: var(--light);
            font-size: 0.9rem;
        }

        .button {
            display: flex;
            align-items: center;
            text-decoration: none;

            transition: 0.2s ease-in-out;
            padding: 0.5rem 1rem;

            .material-icons {
                font-size: 1.5rem;
                color: var(--light);
                transition: 0.2s ease-in-out;
            }

            .text {
                color: var(--light);
                transition: 0.2s ease-in-out;
            }

            &:hover {
                background-color: var(--dark-alt);

                .material-icons:not(.router-link-exact-active .material-icons) {
                    transform: rotate(-10deg);
                }

                .material-icons,
                .text {
                    color: var(--primary);
                }
            }

            &.router-link-exact-active {
                background-color: var(--dark-alt);
                border-right: 5px solid var(--primary);

                .material-icons,
                .text {
                    color: var(--primary);
                }
            }
        }
    }

    &.is-expanded {
        width: var(--sidebar-width);

        .logo {
            img {
                width: 80px !important;
                height: 80px !important;
                margin-bottom: 0px;
            }
        }

        .menu-toggle-wrap {
            top: -3rem;

            .menu-toggle {
                transform: rotate(-180deg);
            }
        }

        h3,
        .button .text {
            opacity: 1;
            visibility: visible;
            max-width: 200px;
            transition: opacity 0.3s ease, visibility 0.3s ease, max-width 0.3s ease;
        }

        .button {
            .material-icons {
                margin-right: 1rem;
            }
        }
    }

    @media (max-width: 1024px) {
        position: absolute;
        z-index: 99;
    }

    .popover {
        background-color: var(--dark);
        color: var(--light);
        border: none;
        box-shadow: none;
        padding: 1rem;
        border-radius: 8px;
        min-width: 200px;
        max-width: 300px;
    }

    .popover-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .popover .button {
        background-color: var(--dark-alt);
        padding: 1rem;
        color: var(--light);
        text-align: left;
        font-size: 1rem;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .popover .button:hover {
        background-color: var(--primary);
        color: var(--light);
    }

    .popover .button:focus {
        outline: none;
    }
}

.menu .button-perfil {
    position: relative;
    height: 2.5rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0.5rem 1rem;

    .gear-icon {
        font-size: 1.5rem;
        color: var(--light);
        opacity: 0;
        transition: all 0.3s ease;
        position: absolute;
        left: 1rem;
        top: 50%;
        transform: translateY(-50%);
    }

    .text,
    .role {
        position: absolute;
        left: 1rem;
        opacity: 0;
        transition: all 0.3s ease;
        white-space: nowrap;
    }
}

/* Cuando la barra lateral está expandida */
aside.is-expanded .button-perfil {
    height: auto; // Permitir que crezca para mostrar ambas líneas
    min-height: 2.5rem;

    .gear-icon {
        opacity: 0;
        transform: translateY(-50%) translateX(-20px);
    }

    .text,
    .role {
        position: relative;
        opacity: 1;
        transform: translateX(0);
        left: 0;
    }
}

/* Cuando la barra lateral está colapsada */
aside:not(.is-expanded) .button-perfil {
    .gear-icon {
        opacity: 1;
        transform: translateY(-50%) translateX(0);
    }

    .text,
    .role {
        opacity: 0;
        transform: translateX(20px);
    }
}

.mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--dark);
    z-index: 100;

    .nav-content {
        padding: 0.5rem;

        .primary-icons {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        .nav-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.5rem;
            color: var(--light);
            text-decoration: none;
            border: none;
            background: none;
            cursor: pointer;
            transition: 0.2s ease;
            width: 25%;

            .material-icons {
                font-size: 1.5rem;
                margin-bottom: 0.25rem;
            }

            .text {
                font-size: 0.75rem;
                white-space: nowrap;
            }

            &:hover,
            &.router-link-active {
                color: var(--primary);
            }
        }
    }
}

.mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.expanded-menu {
    animation: slideUp 0.3s ease;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

// Ajustar el contenido principal para que no se oculte detrás de la barra
:global(main) {
    @media (max-width: 1024px) {
        padding-bottom: 4.5rem !important; // Ajusta este valor según la altura de tu barra
    }
}
</style>