const utils = {
    STATIC_VARIABLES: {
        DEFAULT_ERROR_MESSAGE: "Ha ocurrido un error.",
        APP_VERSION: "1.0.0",
    },
    setupGlobalUtils(app) {
        app.config.globalProperties.$FieldMissing = function (field) {
            utils.msgError(`${field} es obligatorio`);
        };

        app.config.globalProperties.$msgError = function (msg) {
            app.config.globalProperties.$toast.add({
                severity: "error",
                summary: "Error",
                detail: msg,
                life: 3000,
            });
        };

        app.config.globalProperties.$msgSuccess = function (msg) {
            app.config.globalProperties.$toast.add({
                severity: "success",
                summary: "¡Bien hecho!",
                detail: msg,
                life: 3000,
            });
        };

        app.config.globalProperties.$ShowDate = function (date, format, locale, inputFormat) {
            if (!date) return 'Desconocido';
            if (!format) format = 'L';

            var moment = require('moment');
            moment.locale(locale ? locale : 'es');
            var ret = null;
            if (date == 'now') ret = moment();
            else if (typeof (date) == 'number') ret = moment.unix(date);
            else ret = inputFormat ? moment(date, inputFormat) : moment(date);
            return format == 'timestamp' ? ret.unix() : ret.format(format);
        };
    },
};

export default utils;
