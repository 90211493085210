<template>
    <main>
        <h1>Home</h1>
        <!-- <Button label="hoasds"></Button> -->
    </main>
</template>

<script>
export default {
    name: 'HomeView'
}
</script>