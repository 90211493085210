import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";

import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AnimateOnScroll from "primevue/animateonscroll";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import ButtonGroup from "primevue/buttongroup";
import Breadcrumb from "primevue/breadcrumb";
import Card from "primevue/card";
import CascadeSelect from "primevue/cascadeselect";
import Carousel from "primevue/carousel";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Calendar from "primevue/calendar";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DeferredContent from "primevue/deferredcontent";
import Dialog from "primevue/dialog";
import DialogService from "primevue/dialogservice";
import Divider from "primevue/divider";
import Dock from "primevue/dock";
import DynamicDialog from "primevue/dynamicdialog";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import FloatLabel from "primevue/floatlabel";
import FocusTrap from "primevue/focustrap";
import Galleria from "primevue/galleria";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Image from "primevue/image";
import Inplace from "primevue/inplace";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputOtp from "primevue/inputotp";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MeterGroup from "primevue/metergroup";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import Row from "primevue/row";
import SelectButton from "primevue/selectbutton";
import Select from "primevue/select";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Skeleton from "primevue/skeleton";
import Slider from "primevue/slider";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Stepper from "primevue/stepper";
import StepList from "primevue/steplist";
import StepPanels from "primevue/steppanels";
import StepItem from "primevue/stepitem";
import Step from "primevue/step";
import StepPanel from "primevue/steppanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tag from "primevue/tag";
import Terminal from "primevue/terminal";
import Timeline from "primevue/timeline";
import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import VirtualScroller from "primevue/virtualscroller";
import Popover from "primevue/popover";
import DatePicker from "primevue/datepicker";
import ToggleSwitch from "primevue/toggleswitch";
import IftaLabel from "primevue/iftalabel";

export const primeVueComponents = [
  Accordion,
  ToggleSwitch,
  DatePicker,
  Popover,
  StepList,
  StepPanels,
  StepItem,
  Step,
  StepPanel,
  AutoComplete,
  Avatar,
  AvatarGroup,
  Badge,
  BlockUI,
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Carousel,
  CascadeSelect,
  Checkbox,
  Chip,
  Calendar,
  ColorPicker,
  Column,
  ColumnGroup,
  ConfirmDialog,
  ConfirmPopup,
  ContextMenu,
  DataTable,
  DataView,
  DeferredContent,
  Dialog,
  Divider,
  Dock,
  DynamicDialog,
  Fieldset,
  FileUpload,
  FloatLabel,
  Galleria,
  IconField,
  Image,
  Inplace,
  InputGroup,
  InputGroupAddon,
  InputIcon,
  InputMask,
  InputNumber,
  InputOtp,
  InputText,
  Knob,
  Listbox,
  MegaMenu,
  Menu,
  Menubar,
  Message,
  MeterGroup,
  MultiSelect,
  OrderList,
  OrganizationChart,
  Paginator,
  Panel,
  PanelMenu,
  Password,
  PickList,
  ProgressBar,
  ProgressSpinner,
  RadioButton,
  Rating,
  Row,
  SelectButton,
  Select,
  ScrollPanel,
  ScrollTop,
  Slider,
  Skeleton,
  SpeedDial,
  SplitButton,
  Splitter,
  SplitterPanel,
  Stepper,
  Steps,
  TabMenu,
  TabView,
  TabPanel,
  Tag,
  Textarea,
  Terminal,
  TieredMenu,
  Timeline,
  Toast,
  Toolbar,
  ToggleButton,
  Tree,
  TreeSelect,
  TreeTable,
  VirtualScroller,
  IftaLabel,
];

export const primeVueDirectives = [
  { name: "tooltip", directive: Tooltip },
  { name: "badge", directive: BadgeDirective },
  { name: "ripple", directive: Ripple },
  { name: "styleclass", directive: StyleClass },
  { name: "focustrap", directive: FocusTrap },
  { name: "animateonscroll", directive: AnimateOnScroll },
];

export function usePrimeVue(app) {
  app.use(PrimeVue, {
    ripple: true,
    theme: {
      preset: Aura,
      options: {
        darkModeSelector: ".fake-dark-selector",
      },
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(DialogService);
}
