<template>
    <section class="page_404">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 ">
                    <div class="col-sm-10 col-sm-offset-1  text-center">
                        <div class="four_zero_four_bg">
                        </div>
                        <br>
                        <br>
                        <div class="contant_box_404">
                            <h3 class="h2">
                                Al parecer te perdiste...
                            </h3>

                            <p>¡La página que estás buscando no está disponible!</p>

                            <a href="/" class="link_404">Ir a inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style scoped>
.page_404 {
    width: 100%;
    height: 100vh; /* Para ocupar toda la altura de la pantalla */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container .row {
    width: 60%;
}

.four_zero_four_bg {
    background-image: url(https://cdn.dribbble.com/users/722246/screenshots/3066818/404-page.gif);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 70vh;
}

.contant_box_404 {
    text-align: center;
    margin-top: -50px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
}

@media (max-width: 768px) {
    .four_zero_four_bg {
        height: 35vh; 
    }

    .row {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .container .row {
        width: 100%;
    }

    .four_zero_four_bg {
        height: 30vh;
    }

    .link_404 {
        padding: 8px 16px;
        font-size: 0.9rem;
    }

    .contant_box_404 h3 {
        font-size: 1.5rem;
    }

    .contant_box_404 p {
        font-size: 1rem;
    }
}
</style>