import { defineStore } from "pinia";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: localStorage.getItem("access_token_domus") || "",
    refreshToken: localStorage.getItem("refresh_token_domus") || "",
    user: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.token,
  },
  actions: {
    async initializeUser() {
      const token = localStorage.getItem("access_token_domus");
      const refreshToken = localStorage.getItem("refresh_token_domus");

      if (token) {
        try {
          const decoded = jwtDecode(token);

          this.user = {
            first_name: decoded.first_name,
            last_name: decoded.last_name,
            role: decoded.role,
          };

          this.token = token;
          this.refreshToken = refreshToken;
        } catch (error) {
          console.error("Error al decodificar el token:", error);
          this.logout();
        }
      }
    },
    setToken(token) {
      this.token = token;
      localStorage.setItem("access_token_domus", token);
    },
    setRefreshToken(refreshToken) {
      this.refreshToken = refreshToken;
      localStorage.setItem("refresh_token_domus", refreshToken);
    },
    async login(email, password) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/auth/login`,
        { email, password }
      );
      console.log('?xd');

      this.setToken(response.data.access_token);
      this.setRefreshToken(response.data.refresh_token);

      this.initializeUser();
    },
    loadUserFromToken() {
      if (this.token) {
        const decoded = jwtDecode(this.token);
        this.user = {
          first_name: decoded.first_name,
          last_name: decoded.last_name,
          role: decoded.role,
        };
      }
    },
    async logout() {
      this.token = "";
      this.user = null;
      localStorage.removeItem("access_token_domus");
      localStorage.removeItem("refresh_token_domus");
    },
  },
});
