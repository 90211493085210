<template>
    <main>
      <div class="header">
        <h1>Pacientes</h1>
        <Button label="Añadir Paciente" icon="pi pi-plus" class="p-button-success" @click="goToClinicalRecordForm" />
      </div>
      <br>
      <br>
  
      <Card v-for="(file, index) in clinicalRecords" :key="index" class="custom-card">
        <template #title>
          <div class="card-title">
            {{ file.name_provider }} #{{ file.number }}
          </div>
        </template>
        <template #content>
          <div class="card-wrapper">
            <div class="elements-options">
              <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" @click="openMenu($event, index, file)" />
              <Menu :model="menuOptions" popup ref="menu" />
            </div>
            <div class="card-content">
              <div class="elements-info">
                <div class="info-row">
                  <p class="bold">Paciente:</p>
                  <span>{{ file.name }}</span>
                </div>
                <div class="info-row">
                  <p class="bold">Rut:</p>
                  <span>{{ file.rut }}</span>
                </div>
                <div class="info-row">
                  <p class="bold">Fecha:</p>
                  <span>{{ file.date }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </main>
  </template>
  
  <script>
  export default {
    data() {
      return {
        clinicalRecords: null,
        menuOptions: [
          {
            label: 'Descargar',
            icon: 'pi pi-download',
            command: () => {
            //   this.downloadFile(this.selectedFile);
            }
          }
        ],
        selectedFile: null
      }
    },
    mounted() {
    //   this.getClinicalRecords();
    },
    methods: {
    //   openMenu(event, index, file) {
    //     this.selectedFile = file;
    //     this.$refs.menu[index].toggle(event);
    //   },
    //   async downloadFile(file) {
    //     try {
    //       const response = await this.$axios.get(`/clinical-records/${file.id}/download`, {
    //         responseType: 'blob'
    //       });
  
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement('a');
    //       link.href = url;
    //       link.setAttribute('download', `Ficha Clínica #${file.number}.pdf`);
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   },
      async getClinicalRecords() {
        try {
          let { data } = await this.$axios.get('/patients');
          if (data.status) this.clinicalRecords = data.clinicalRecords;
        } catch (error) {
          console.error(error);
        }
      },
      goToClinicalRecordForm() {
        this.$router.push({ name: "Crear Ficha clínica" });
      },
    },
  };
  </script>
  
  <style scoped>
  .custom-card {
    position: relative;
    margin-bottom: 1rem;
  }
  
  .card-wrapper {
    position: relative;
    padding-top: 1rem;
  }
  
  .elements-options {
    position: absolute;
    top: 25px;
    right: 0;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
  }
  
  .info-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .info-row span {
    margin: 0;
  }
  
  .elements-info {
    flex: 1;
  }
  
  .elements-info p {
    margin: 0;
    line-height: 1.5;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  ::v-deep .p-password input {
    width: 100% !important;
  }
  </style>