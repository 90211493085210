import { createRouter, createWebHistory } from "vue-router";
import { roleMiddleware } from "@/middlewares/roleMiddleware";
import { useAuthStore } from "@/stores/auth";
import Home from "@/views/HomeView.vue";
import Patients from "@/views/Patients/listView.vue";
import Providers from "@/views/Providers/listView.vue";
import Login from "@/views/Auth/LoginView.vue";
import Register from "@/views/Auth/RegisterView.vue";
import NotFound from "@/views/NotFoundView.vue";
import AdministratorsListView from "@/views/Administrators/listView.vue";
import ClinicalRecordView from "@/views/Clinical Record/listView.vue";
import CreateClinicalRecordView from "@/views/Clinical Record/createView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/patients",
    name: "Pacientes",
    component: Patients,
    meta: { requiresAuth: true },
  },
  {
    path: "/providers",
    name: "Prestadores",
    component: Providers,
    meta: { requiresAuth: true },
  },
  {
    path: "/administrators",
    name: "Administradores",
    component: AdministratorsListView,
    meta: { requiresAuth: true },
    beforeEnter: roleMiddleware,
  },
  {
    path: "/clinical_record",
    name: "Fichas clínica",
    component: ClinicalRecordView,
    meta: { requiresAuth: true },
  },
  {
    path: "/create_clinical_record",
    name: "Crear Ficha clínica",
    component: CreateClinicalRecordView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { layout: "no-sidebar" },
  },
  {
    path: "/register",
    name: "Registro",
    component: Register,
    meta: { layout: "no-sidebar" },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  await authStore.initializeUser();

  if (to.meta.requiresAuth) {
    if (!authStore.isAuthenticated) {
      next({ name: "Login" }); 
    } else {
      if (to.name == "Administradores" || to.name == "Prestadores") {
        roleMiddleware(to, from, next);
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
