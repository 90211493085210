import { ref } from 'vue'

export const createDevicePlugin = () => {
    const device = {
        isMobile: ref(false),
        isTablet: ref(false),
        isDesktop: ref(false)
    }

    const checkDevice = () => {
        const width = window.innerWidth
        device.isMobile.value = width < 768
        device.isTablet.value = width >= 768 && width < 1024
        device.isDesktop.value = width >= 1024
    }

    // Verificación inicial y listener
    if (typeof window !== 'undefined') {
        checkDevice()
        window.addEventListener('resize', checkDevice)
    }

    return device
}