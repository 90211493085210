<template>
  <main>
    <div class="header">
      <h1>Fichas Clínicas</h1>
      <Button label="Crear Ficha Clínica" icon="pi pi-plus" class="p-button-success" @click="goToClinicalRecordForm" />
    </div>
    <br>
    <br>

    <div v-if="clinicalRecords && clinicalRecords.length">
      <CustomPanel v-for="(file, index) in clinicalRecords" :key="index"
        :title="`${file.name_provider} #${file.number}`" class="mb20">
        <template #options>
          <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
            @click="openMenu($event, index, file)" />
          <Menu :model="menuOptions" popup ref="menu" />
        </template>
        <div class="info-row">
          <p class="bold">Paciente:</p>
          <span>{{ file.name }}</span>
        </div>
        <div class="info-row">
          <p class="bold">RUT:</p>
          <span>{{ file.rut }}</span>
        </div>
        <div class="info-row">
          <p class="bold">Fecha:</p>
          <span>{{ $ShowDate(file.date) }}</span>
        </div>
      </CustomPanel>
      <br>
    </div>
    <div v-else class="text-center">
      No hay registros
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      clinicalRecords: null,
      menuOptions: [
        {
          label: 'Descargar',
          icon: 'pi pi-download',
          command: () => {
            this.downloadFile(this.selectedFile);
          },
        },
        {
          label: 'Eliminar',
          icon: 'pi pi-trash',
          command: () => {
            this.deleteFile(this.selectedFile);
          },
        }
      ],
      selectedFile: null
    }
  },
  mounted() {
    this.getClinicalRecords();
  },
  methods: {
    openMenu(event, index, file) {
      this.selectedFile = file;
      this.$refs.menu[index].toggle(event);
    },
    async downloadFile(file) {
      try {
        const response = await this.$axios.get(`/clinical-records/${file.id}/download`, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Ficha Clínica #${file.number}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(error);
      }
      console.log(file);
    },
    async deleteFile(file) {
      try {
        const { data } = await this.$axios.delete(`/clinical-records/${file.id}/delete`);
        if (data.status) {
          this.clinicalRecords = data.clinicalRecords;
          this.$msgSuccess('Se ha borrado con éxito la Ficha Clínica');
        }
      } catch (error) {
        this.$msgError('No se ha podido borrar la Ficha Clínica')
      }
    },
    async getClinicalRecords() {
      try {
        let { data } = await this.$axios.get('/clinical-records');
        if (data.status) this.clinicalRecords = data.clinicalRecords;
      } catch (error) {
        console.error(error);
      }
    },
    goToClinicalRecordForm() {
      this.$router.push({ name: "Crear Ficha clínica" });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

::v-deep .p-password input {
  width: 100% !important;
}
</style>