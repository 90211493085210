<template>
  <div class="app">
    <Toast />
    <AppSidebar v-if="!isLoginPage" />
    <main :class="['main-content', { 'no-padding': isLoginPage }]">
      <router-view></router-view>
    </main>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import AppSidebar from './components/AppSidebar.vue';

const route = useRoute();
const isLoginPage = computed(() => route.name === 'Login' || route.name == 'Registro' || route.name == 'NotFound');
</script>

<style lang="scss">
:root {
  --primary: #71B700;
  --primary-alt: #006B23;
  --grey: #76736F;
  --dark: #006B23;
  --dark-secondary: #006B23;
  --dark-alt: #C0D982;
  --light: #F8F8ED;
  --sidebar-width: 300px;
  --gradient-bg: linear-gradient(135deg, #71B700 0%, #006B23 100%);
}

body {
  --theme-light: true !important;
  overflow: hidden;
}

html,
body {
  height: 100%;
  margin: 0;
  color-scheme: light;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira sans', sans-serif;
}

body {
  background-color: var(--light);
}

button {
  cursor: pointer;
  border-radius: 20px !important;
}

.app {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 2rem;
  height: 100vh;

  &.no-padding {
    overflow-y: hidden;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .main-content {
    padding-bottom: 5rem;
  }
}
</style>
