<template>
    <main>
        <div class="header">
            <h1>Prestadores</h1>
            <Button label="Añadir Prestador" icon="pi pi-plus" class="p-button-success" @click="OpenAddProvider()" />
            <!-- <Button label="Añadir Prestador" icon="pi pi-plus" class="p-button-success" -->
            <!-- @click="goToClinicalRecordForm" /> -->
        </div>
        <br />
        <br />

        <div v-if="providers && providers.length">
            <CustomPanel v-for="(provider, index) in providers" :key="index"
                :title="`${provider.first_name} ${provider.last_name}`" class="mb20">
                <template #options>
                    <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
                        @click="openActions($event, index, provider)" />
                    <Menu :model="actions" popup ref="menu" />
                </template>
                <div class="info-row">
                    <p class="bold">RUT:</p>
                    <span>{{ provider.rut }}</span>
                </div>
                <div class="info-row">
                    <p class="bold">Profesión:</p>
                    <span>{{ provider.profession }}</span>
                </div>
                <div class="info-row">
                    <p class="bold">Estado:</p>
                    <span>{{ provider.is_active ? 'Activ' : 'Inactiv' }}{{ provider.gender === 'female' ? 'a' : 'o'
                        }}</span>
                </div>
            </CustomPanel>
            <br />
        </div>
        <div v-else class="text-center">No hay registros</div>

        <CustomModal v-if="addProvider" title="Añadir Prestador" :data="addProvider" @close="closeModal()">
            <template v-slot>
                <Stepper value="1" @update:value="currentStep = $event" class="stepper-container">
                    <StepItem value="1">
                        <Step>Datos Personales</Step>
                        <StepPanel v-slot="{ activateCallback }">
                            <div class="step-panel">
                                <div class="form-row">
                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="name" v-model="addProvider.name" autocomplete="off" />
                                        </IconField>
                                        <label for="name">Nombre</label>
                                    </FloatLabel>

                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="last_name" v-model="addProvider.lastName"
                                                autocomplete="off" />
                                        </IconField>
                                        <label for="last_name">Apellidos</label>
                                    </FloatLabel>
                                </div>
                                <div class="form-row">
                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="email" v-model="addProvider.email" autocomplete="off" />
                                        </IconField>
                                        <label for="email">Email</label>
                                    </FloatLabel>

                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <Password id="password" v-model="addProvider.password" inputId="on_label"
                                                aria-describedby="password-help" class="p-password"
                                                promptLabel="Introduce tu contraseña" weakLabel="Débil"
                                                mediumLabel="Mediana" strongLabel="Fuerte" />
                                        </IconField>
                                        <label for="password">Contraseña</label>
                                    </FloatLabel>
                                </div>
                                <div class="form-row">
                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="phone" v-model="addProvider.phone" autocomplete="off" />
                                        </IconField>
                                        <label for="phone">Celular</label>
                                    </FloatLabel>

                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="rut" v-model="addProvider.rut" autocomplete="off" />
                                        </IconField>
                                        <label for="rut">RUT</label>
                                    </FloatLabel>
                                </div>
                                <div class="form-row">
                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="Profession" v-model="addProvider.profession"
                                                autocomplete="off" />
                                        </IconField>
                                        <label for="Profession">Profesión</label>
                                    </FloatLabel>

                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <InputText id="Occupation" v-model="addProvider.occupation"
                                                autocomplete="off" />
                                        </IconField>
                                        <label for="Occupation">Ocupación</label>
                                    </FloatLabel>
                                </div>
                                <div class="form-row">
                                    <FloatLabel variant="on">
                                        <DatePicker v-model="addProvider.birthdate" inputId="birthdate" showIcon
                                            iconDisplay="input" dateFormat="dd/mm/yy" />
                                        <label for="birthdate">Fecha de Nacimiento</label>
                                    </FloatLabel>

                                    <div class="flex w-1/2">
                                        <ToggleButton v-model="addProvider.account_verified" class="mr5"
                                            onLabel="Verificado" offLabel="No Verificado" />

                                        <ToggleButton v-model="addProvider.is_active" onLabel="Activo"
                                            offLabel="Inactivo" />
                                    </div>
                                </div>
                            </div>
                            <div class="button-container pt50">
                                <Button label="Siguiente" @click="activateCallback('2')" />
                            </div>
                        </StepPanel>
                    </StepItem>
                    <StepItem value="2">
                        <Step>Datos Extras</Step>
                        <StepPanel v-slot="{ activateCallback }">
                            <div class="step-panel">
                                <div class="form-row">
                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <Select v-model="addProvider.gender" class="w100" inputId="gender"
                                                :options="genders" optionLabel="name" />
                                        </IconField>
                                        <label for="gender">Género</label>
                                    </FloatLabel>

                                    <FloatLabel variant="on" class="w-1/2">
                                        <IconField>
                                            <InputIcon class="pi pi-search" />
                                            <Select v-model="addProvider.maritalStatus" class="w100"
                                                inputId="maritalStatus" :options="genders" optionLabel="name" />
                                        </IconField>
                                        <label for="maritalStatus">Estado Civil</label>
                                    </FloatLabel>
                                </div>
                                <br />
                                <div class="flex flex-col space-y-4">
                                    <div class="flex gap-2 w-full">
                                        <FloatLabel variant="on" class="flex-1">
                                            <IconField>
                                                <InputIcon class="pi pi-search" />
                                                <InputText v-model="addProvider.address
                                                    .searchAddress
                                                    " placeholder="Escribe una dirección" class="w-full"
                                                    @keyup.enter="searchLocation" />
                                            </IconField>
                                        </FloatLabel>
                                        <Button label="Buscar" icon="pi pi-search" @click="searchLocation"
                                            class="h-[42px]" />
                                    </div>

                                    <!-- Contenedor del mapa -->
                                    <div class="w-full border rounded-lg overflow-hidden shadow-sm">
                                        <div id="map" ref="map" class="h-[200px] w-full"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="button-container pt50">
                                <Button class="m5" label="Volver" severity="secondary" @click="activateCallback('1')" />
                                <Button class="m5" label="Siguiente" @click="activateCallback('3')" />
                            </div>
                        </StepPanel>
                    </StepItem>
                    <StepItem value="3">
                        <Step>Documentos</Step>
                        <StepPanel v-slot="{ activateCallback }">
                            <div class="file-upload-container">
                                <FileUpload mode="basic" @select="onUpload" accept=".pdf" customUpload auto
                                    chooseLabel="Carnet de Identidad" />
                            </div>
                            <br />
                            <div class="button-container">
                                <Button label="Volver" severity="secondary" @click="activateCallback('2')" />
                            </div>
                        </StepPanel>
                    </StepItem>
                </Stepper>
            </template>
            <template v-slot:actions>
                <div class="modal-actions">
                    <button class="cancel-button" @click="addProvider = null">
                        Cancelar
                    </button>
                    <button class="confirm-button" @click="confirmAddProvider">
                        Confirmar
                    </button>
                </div>
            </template>
        </CustomModal>
    </main>
</template>

<script>
import { nextTick } from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
export default {
    data() {
        return {
            providers: null,
            addProvider: null,
            actions: [
                {
                    label: "Descargar",
                    icon: "pi pi-download",
                    command: () => {
                        //   this.downloadFile(this.selectedProvider);
                    },
                },
            ],
            genders: [
                { name: "Masculino", code: "male" },
                { name: "Femenino", code: "female" },
                { name: "Otro", code: "other" },
            ],
            selectedProvider: null,
            isMapInitialized: false,
            currentStep: 1
        };
    },
    mounted() {
        this.getProviders();
    },
    methods: {
        openActions(event, index, file) {
            this.selectedProvider = file;
            this.$refs.menu[index].toggle(event);
        },
        closeModal() {
            this.addProvider = null;
            this.isMapInitialized = false;
        },
        async getProviders() {
            try {
                let { data } = await this.$axios.get("/providers");
                if (data.status) this.providers = data.providers;
            } catch (error) {
                console.error(error);
            }
        },
        confirmAddProvider() {
            if (!this.addProvider.name) return this.$FieldMissing('Nombre');
            if (!this.addProvider.lastName) return this.$FieldMissing('Apellidos');
            if (!this.addProvider.email) return this.$FieldMissing('Email');
            if (!this.addProvider.password) return this.$FieldMissing('Contraseña');
            if (!this.addProvider.phone) return this.$FieldMissing('Celular');
            if (!this.addProvider.rut) return this.$FieldMissing('RUT');
            if (!this.addProvider.profession) return this.$FieldMissing('Profesión');
            if (!this.addProvider.occupation) return this.$FieldMissing('Ocupación');
            if (!this.addProvider.birthdate) return this.$FieldMissing('Fecha de Nacimiento');
            if (!this.addProvider.gender) return this.$FieldMissing('Género');
            if (!this.addProvider.maritalStatus) return this.$FieldMissing('Estado Civil');
            if (!this.addProvider.address || !this.addProvider.address.street_name || !this.addProvider.address.latitude || !this.addProvider.address.longitude) return;
            if (!this.addProvider.id_card) return this.$FieldMissing('Carnet de Identidad');
            // if (!this.addProvider.certificate_criminal_record) return;

            const formData = new FormData();

            const birthdate = new Date(this.addProvider.birthdate);
            const birthdateUnixTimestamp = Math.floor(birthdate.getTime() / 1000);

            const skipFields = ['address', 'gender', 'maritalStatus', 'account_verified', 'is_active', 'birthdate'];

            const simplifiedAddress = {
                street_name: this.addProvider.address.street_name,
                latitude: this.addProvider.address.latitude,
                longitude: this.addProvider.address.longitude,
            };

            formData.append("gender", this.addProvider.gender.code);
            formData.append("marital_status", this.addProvider.maritalStatus.code);
            formData.append("address", JSON.stringify(simplifiedAddress));
            formData.append("account_verified", this.addProvider.account_verified ? 1 : 0);
            formData.append("is_active", this.addProvider.is_active ? 1 : 0);
            formData.append("birthdate", birthdateUnixTimestamp);

            for (const key in this.addProvider) {
                if (!skipFields.includes(key)) {
                    formData.append(key, this.addProvider[key]);
                }
            }

            this.$axios
                .post("/providers/create", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    console.log("Success:", response.data);
                    // this.addProvider = null;
                })
                .catch((error) => {
                    console.error(
                        "Error:",
                        error.response ? error.response.data : error.message
                    );
                });
        },
        async initializeMap() {
            if (!this.addProvider) return;
            await this.loadLeafletStyles();
            await nextTick();

            if (this.addProvider.address.map) {
                this.addProvider.address.map.remove();
            }

            const defaultLat = -33.4489;
            const defaultLng = -70.6693;

            this.addProvider.address.map = L.map(this.$refs.map).setView(
                [defaultLat, defaultLng],
                13
            );

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 19,
            }).addTo(this.addProvider.address.map);

            const markerIcon = L.icon({
                iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
                iconSize: [25, 41],  // Tamaño del ícono
                iconAnchor: [12, 41], // Punto en el que el ícono está anclado
                popupAnchor: [1, -34], // Ubicación del popup respecto al ícono
                shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png", // Sombra
                shadowSize: [41, 41]  // Tamaño de la sombra
            });

            this.addProvider.address.marker = L.marker([defaultLat, defaultLng], {
                draggable: true,
                icon: markerIcon  // Establecer el ícono personalizado
            }).addTo(this.addProvider.address.map);

            this.addProvider.address.marker.on("dragend", (event) => {
                const position = event.target.getLatLng();
                this.addProvider.address.latitude = position.lat;
                this.addProvider.address.longitude = position.lng;
                this.fetchAddress(position.lat, position.lng);
            });

            if (navigator.geolocation && this.addProvider && this.addProvider.address) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        this.addProvider.address.map.setView(
                            [latitude, longitude],
                            13
                        );
                        this.addProvider.address.marker.setLatLng([
                            latitude,
                            longitude,
                        ]);
                        this.addProvider.address.latitude = latitude;
                        this.addProvider.address.longitude = longitude;
                        this.fetchAddress(latitude, longitude);
                    },
                    (error) => {
                        console.log("Error getting location:", error);
                    }
                );
            }
        },
        async loadLeafletStyles() {
            if (!document.querySelector("#leaflet-css")) {
                const link = document.createElement("link");
                link.id = "leaflet-css";
                link.rel = "stylesheet";
                link.href = "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";
                document.head.appendChild(link);
                await new Promise(resolve => link.onload = resolve);  // Esperar a que el CSS se cargue
            }
        },
        searchLocation() {
            if (
                this.addProvider.address &&
                this.addProvider.address.searchAddress.trim() !== ""
            ) {
                fetch(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
                        this.addProvider.address.searchAddress
                    )}`
                )
                    .then((response) => response.json())
                    .then((data) => {
                        if (data && data.length > 0) {
                            const location = data[0];
                            const lat = parseFloat(location.lat);
                            const lon = parseFloat(location.lon);

                            // Mueve el mapa y el marcador a la ubicación buscada
                            this.addProvider.address.map.setView(
                                [lat, lon],
                                13
                            );

                            this.addProvider.address.marker.setLatLng([
                                lat,
                                lon,
                            ]);
                            this.addProvider.address.latitude = lat;
                            this.addProvider.address.longitude = lon;
                            this.addProvider.address.searchAddress =
                                location.display_name;
                        } else {
                            alert("Dirección no encontrada");
                        }
                    })
                    .catch((error) => {
                        console.error("Error al buscar la dirección:", error);
                    });
            }
        },
        fetchAddress(lat, lng) {
            if (!this.addProvider || !this.addProvider.address) return;
            fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
            )
                .then((response) => response.json())
                .then((data) => {
                    this.addProvider.address.street_name = data.display_name;
                })
                .catch((error) => {
                    console.error("Error fetching address:", error);
                });
        },
        OpenAddProvider() {
            this.addProvider = {
                address: {
                    latitude: null,
                    longitude: null,
                    street_name: "",
                    map: null,
                    marker: null,
                    searchAddress: "",
                },
            };

            this.currentStep = '1';
            // this.initializeMap();
        },
        onUpload(event) {
            const file = event.files[0];

            if (file) {
                this.addProvider.id_card = file;
                this.$toast.add({
                    severity: "info",
                    summary: "Success",
                    detail: "¡Archivo subido!",
                    life: 3000,
                });
            }
        },
    },
    computed: {
        isFormComplete() {
            return (
                this.addProvider.name &&
                this.addProvider.lastName &&
                this.addProvider.email &&
                this.addProvider.password &&
                this.addProvider.phone &&
                this.addProvider.rut &&
                this.addProvider.profession &&
                this.addProvider.occupation &&
                this.addProvider.birthdate &&
                this.addProvider.gender &&
                this.addProvider.maritalStatus &&
                this.addProvider.address.street_name
                // this.addProvider.certificate_criminal_record
            );
        },
    },
    watch: {
        currentStep(newStep) {
            if (newStep == 2 && !this.isMapInitialized) {
                this.initializeMap();
                this.isMapInitialized = true;
            }
        },
    }
};
</script>

<style>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.step-panel {
    flex: 1;
    padding: 1rem;
}

.form-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
        gap: 1rem;
    }

    .form-row .w-1\/2 {
        width: 100% !important;
    }

    .step-panel {
        flex: 1;
        max-height: 300px !important;
        overflow-y: auto !important;
        padding: 1rem;
    }
}

.p-inputtext,
.p-password,
.p-calendar {
    width: 100%;
}

/* Ajustes para los botones de toggle en móvil */
@media (max-width: 768px) {
    

    .flex.w-1\/2 {
        width: 100% !important;
        justify-content: space-between;
    }
}

.file-upload-container {
    width: 100%;
}

.p-fileupload-basic {
    width: 100%;
}
</style>