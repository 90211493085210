<template>
    <Dialog v-model="modalAddUser" modal :header="title" :style="{ width: '50vw' }"
        :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <Divider :style="{ 'margin': '0px 0px 1rem 0px' }" />
        <div v-if="formNewUser">
            <slot></slot>
        </div>

        <div class="actions" v-if="!hideActions">
            <Divider :style="{ 'margin': '1rem 0px 0px 0px' }" />
            <slot name="actions"></slot>
        </div>
        <!-- <Divider :style="{ 'margin': '1rem 0px 0px 0px' }" />
        <template #footer>
            <Button label="Cancelar" class="p-button-text" @click="modalAddUser = false" />
            <Button label="Guardar" class="p-button-primary" @click="SaveUser()" />
        </template> -->
    </Dialog>
</template>

<script>
export default {
    name: 'ModalTmp',
    props: {
        title: {
            type: String,
            default: null
        },
        hideActions: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

::v-deep .p-password input {
    width: 100% !important;
}
</style>