import { useAuthStore } from "@/stores/auth";

export function roleMiddleware(to, from, next) {
  const authStore = useAuthStore();
  const user = authStore.user;

  if (user && user.role === "superadmin") {
    next();
  } else {
    next({ name: "Home" });
  }
}
