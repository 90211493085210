<template>
    <main>
        <div class="header">
            <Button label="Volver" icon="pi pi-arrow-left" class="p-button-secondary" @click="$router.go(-1);" />
            <h1>Ficha de Atención Clínica</h1>
            <Button label="Crear Ficha Clínica" icon="pi pi-plus" class="p-button-success"
                @click="generateClinicalRecord()" />
        </div>

        <TabView class="tabview-custom m50 br10">
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar class="material-icons" shape="circle">personal_injury</Avatar>
                        <span class="font-bold white-space-nowrap">Paciente</span>
                    </div>
                </template>
                <div class="m-0">
                    <div class="form-group">
                        <label for="name">Nombre paciente:</label>
                        <InputText v-model="form.name" id="name" />
                    </div>

                    <div class="form-group">
                        <label for="rut">RUT:</label>
                        <InputText v-model="form.rut" id="rut" />
                    </div>

                    <div class="form-group">
                        <label for="age">Edad</label>
                        <InputNumber v-model="form.age" id="age" />
                    </div>

                    <div class="form-group">
                        <label for="phone">Fono</label>
                        <InputText v-model="form.phone" id="phone" />
                    </div>

                    <div class="form-group">
                        <label for="responsible_family_member">Familiar Responsable</label>
                        <InputText v-model="form.responsible_family_member" id="responsible_family_member" placeholder="(Opcional)"/>
                    </div>

                    <div class="form-group">
                        <label for="date">Fecha</label>
                        <DatePicker v-model="form.date" inputId="birthdate" showIcon iconDisplay="input" dateFormat="dd/mm/yy" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar class="material-symbols-outlined" shape="circle">demography</Avatar>
                        <span class="font-bold white-space-nowrap">Antecedentes</span>
                    </div>
                </template>
                <div class="m-0">
                    <div class="form-group">
                        <label for="older_adults">Adultos Mayores:</label>
                        <InputNumber v-model="form.older_adults" id="older_adults" />
                    </div>

                    <div class="form-group">
                        <label for="minor_adults">Adultos Menores:</label>
                        <InputNumber v-model="form.minor_adults" id="minor_adults" />
                    </div>

                    <div class="form-group">
                        <label for="children">Niños:</label>
                        <InputNumber v-model="form.children" id="children" />
                    </div>

                    <div class="form-group">
                        <label for="medications">Medicamentos/Tratamientos:</label>
                        <Textarea v-model="form.medications" id="medications" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="health_history">Antecedentes morbidos de salud:</label>
                        <Textarea v-model="form.health_history" id="health_history" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="reason">Motivo de consulta:</label>
                        <InputText v-model="form.reason" id="reason" />
                    </div>

                    <div class="form-group">
                        <label for="anamnesis">Anamnesis:</label>
                        <Textarea v-model="form.anamnesis" id="anamnesis" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="physical_examination">Exámen físico:</label>
                        <Textarea v-model="form.physical_examination" id="physical_examination" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="diagnosis">Diagnóstico:</label>
                        <Textarea v-model="form.diagnosis" id="diagnosis" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="indications">Indicaciones:</label>
                        <Textarea v-model="form.indications" id="indications" rows="3" />
                    </div>

                    <div class="form-group">
                        <label for="others">Otros:</label>
                        <Textarea v-model="form.others" id="others" rows="3" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <div class="flex align-items-center gap-2">
                        <Avatar class="material-symbols-outlined" shape="circle">person_apron</Avatar>
                        <span class="font-bold white-space-nowrap">Prestador</span>
                    </div>
                </template>
                <div class="m-0">
                    <div class="form-group">
                        <label for="name_provider">Nombre prestador:</label>
                        <InputText v-model="form.name_provider" id="name_provider" />
                    </div>

                    <div class="form-group">
                        <label for="rut_provider">RUT:</label>
                        <InputText v-model="form.rut_provider" id="rut_provider" />
                    </div>

                    <div class="form-group">
                        <label for="registration_number">N° Registro:</label>
                        <InputText v-model="form.registration_number" id="registration_number" />
                    </div>

                    <div class="form-group">
                        <label for="signature">Firma:</label>
                        <Textarea v-model="form.signature" id="signature" rows="5" />
                    </div>
                </div>
            </TabPanel>
        </TabView>
    </main>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: "",
                rut: "",
                age: null,
                phone: "",
                responsible_family_member: "",
                date: null,
                older_adults: null,
                minor_adults: null,
                children: null,
                medications: "",
                health_history: "",
                reason: "",
                anamnesis: "",
                physical_examination: "",
                diagnosis: "",
                indications: "",
                others: "",
                name_provider: "",
                rut_provider: "",
                registration_number: "",
                signature: "",
            },
        };
    },
    methods: {
        async generateClinicalRecord() {
            if(!this.form.name) return this.$FieldMissing('Nombre Paciente');
            if(!this.form.rut) return this.$FieldMissing('RUT Paciente');
            if(!this.form.age) return this.$FieldMissing('Edad Paciente');
            if(!this.form.name_provider) return this.$FieldMissing('Nombre Prestador');
            if(!this.form.rut_provider) return this.$FieldMissing('Rut Prestador');
            if(!this.form.registration_number) return this.$FieldMissing('N° de Registro');
            if(!this.form.signature) return this.$FieldMissing('Firma Prestador');

            try {
                const response = await this.$axios.post('/clinical-record/generate/pdf', this.form, {
                    responseType: 'blob',
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Ficha Clínica.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error al generar la ficha clínica:', error);
            }
            console.log(this.form);
        },
    },
};
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

::v-deep .p-password input {
    width: 100% !important;
}

.tabview-custom {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    overflow: hidden;
    max-height: 750px;
    /* Define un alto máximo para las pestañas */
    overflow-y: auto;
}

ul.p-tabview-tablist {
    background-color: white !important;
}

.form-container {
    max-width: 800px;
    margin: auto;
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
}

.form-group {
    display: flex;
    margin-bottom: 15px;
}

label {
    flex-basis: 30%;
    margin-right: 10px;
    font-weight: bold;
}

input,
textarea,
select {
    flex-basis: 70%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.section-title {
    background-color: #ddd;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: bold;
}

.button-container {
    text-align: center;
}

button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}
</style>
