<template> 
	<div
		class="px-6 py-20 md:px-12 lg:px-20 flex items-center justify-center w-full min-h-screen bg-gradient-to-br from-[#71B700] to-[#006B23]">
		<div v-if="loading" class="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
			<ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent"
				animationDuration=".5s" aria-label="Cargando" />
		</div>
		<div class="p-12 shadow-lg text-center lg:w-[30rem] backdrop-blur-md rounded-xl bg-[#F8F8ED]/90">
			<div class="text-4xl font-medium mb-12">
				<img src="../../assets/logo_with_slogan.png" alt="Domus Logo" style="display: block; margin-left: auto; margin-right: auto; width: 200px; height: 80px;" />
			</div>
			<form @submit.prevent="handleSubmit">
				<InputText v-model="form.email" type="text"
					class="!appearance-none placeholder:!text-[#76736F] !border-0 !p-4 !w-full !outline-0 !text-xl !block !mb-6 !bg-[#C0D982]/30 !text-[#006B23] !rounded-full"
					placeholder="Correo electrónico" />
				<div class="relative mb-6">
					<InputText v-model="form.password" :type="passwordVisible ? 'text' : 'password'"
						class="!appearance-none placeholder:!text-[#76736F] !border-0 !p-4 !w-full !outline-0 !text-xl !block !bg-[#C0D982]/30 !text-[#006B23] !rounded-full"
						placeholder="Contraseña" />
					<i @click="togglePasswordVisibility" class="fa"
						:class="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
						style="position: absolute; right: 20px; top: 50%; transform: translateY(-50%); cursor: pointer; color: #006B23;"></i>
				</div>
				<button type="submit"
					class="max-w-40 w-full rounded-full appearance-none border-0 p-4 outline-0 text-xl mb-6 font-medium bg-[#71B700] text-[#F8F8ED] hover:bg-[#C0D982] active:bg-[#006B23] cursor-pointer transition-colors duration-150">
					Ingresar
				</button>
				<a class="cursor-pointer font-medium block text-center text-[#006B23] hover:text-[#71B700]"
					href="/register">¿No tienes cuenta? Regístrate</a>
			</form>
		</div>
	</div>
</template>

<script>
import { useAuthStore } from '@/stores/auth';
import InputText from 'primevue/inputtext';

export default {
	components: {
		InputText,
	},
	data() {
		return {
			form: {
				email: '',
				password: ''
			},
			loading: false,
			passwordVisible: false,
		};
	},
	methods: {
		async handleSubmit() {
			const authStore = useAuthStore();
			this.loading = true;

			try {
				await authStore.login(this.form.email, this.form.password);
				this.$router.push('/');
			} catch (error) {
				this.$msgError('Credenciales incorrectas');
			} finally {
				this.loading = false;
			}
		},
		togglePasswordVisibility() {
			this.passwordVisible = !this.passwordVisible;
		}
	}
}
</script>

<style scoped>
</style>
