<template>
    <transition name="fade" appear>
        <div v-if="isVisible" :class="`modal-backdrop ${currentClass}`" @click="closeModal">
            <transition name="slide-fade" appear>
                <div class="modal-content" @click.stop>
                    <div class="modal-header">
                        <h2>{{ title }}</h2>
                        <button class="close-button" @click="closeModal">×</button>
                    </div>

                    <hr class="modal-divider" />

                    <div class="modal-body">
                        <slot></slot>
                    </div>

                    <hr class="modal-divider" v-if="$slots.actions" />

                    <div class="modal-footer" v-if="$slots.actions">
                        <slot name="actions"></slot>
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        modalClass: {
            type: [String, Array],
            default: ''
        },
        data: {
            type: [Object, String, Number],
            default: () => null,
        },
    },
    computed: {
        isVisible() {
            return this.data !== null;
        },
        currentClass() {
            return typeof this.modalClass == 'string' ? this.modalClass : (this.$device.isMobile ? this.modalClass[0] : this.modalClass[1]);
        },
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    width: 90vw;
    max-width: 600px;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    position: relative;
}

/* Transición para el fondo oscuro */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Transición para el contenido del modal */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

/* Resto de los estilos se mantienen igual */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header h2 {
    margin: 0;
}

.modal-divider {
    border: none;
    border-top: 1px solid #ccc;
    margin: 0 -1.5rem;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body {
    padding: 1.5rem 0;
    flex: 1;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 15px 15px 0px 15px;
}

@media (max-width: 600px) {
    .modal-footer {
        flex-direction: column;
        align-items: stretch;
    }
}
</style>