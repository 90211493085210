<template>
    <main>
        <div class="header">
            <h1>Lista de administradores</h1>
            <Button label="Añadir Administrador" icon="pi pi-plus" class="p-button-success" @click="OpenModal()" />
        </div>
        <br>
        <div v-if="administrators && administrators.length">
            <Card v-for="(admin, index) in administrators" :key="index" class="custom-card">
                <template #title>
                    <div class="card-title">
                        {{ admin.full_name }} ({{ admin.is_active? 'Activo' : 'Inactivo' }})
                    </div>
                </template>
                <template #content>
                    <div class="card-wrapper">
                        <div class="elements-options">
                            <!-- <Button icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" -->
                                <!-- @click="openMenu($event, index, admin)" /> -->
                            <!-- <Menu :model="menuOptions" popup ref="menu" /> -->
                        </div>
                        <div class="card-content">
                            <div class="elements-info">
                                <div class="info-row">
                                    <p class="bold">RUT:</p>
                                    <span>{{ admin.rut }}</span>
                                </div>
                                <div class="info-row">
                                    <p class="bold">Email:</p>
                                    <span>{{ admin.email }}</span>
                                </div>
                                <div class="info-row">
                                    <p class="bold">Celular:</p>
                                    <span>+56{{ admin.phone }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Card>
        </div>
        <div v-else class="text-center">
            No hay registros
        </div>

        <Dialog v-model:visible="modalAddUser" modal header="Nuevo Administrador" :style="{ width: '50vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <Divider :style="{ 'margin': '0px 0px 1rem 0px' }" />
            <div v-if="formNewUser">
                <div class="flex gap-4">
                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="first_name">Nombres</label>
                        <InputText id="first_name" v-model="formNewUser.first_name"
                            aria-describedby="first_name-help" />
                        <small id="first_name-help">Ingresa tu nombre</small>
                    </div>

                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="last_name">Apellidos</label>
                        <InputText id="last_name" v-model="formNewUser.last_name" aria-describedby="last_name-help" />
                        <small id="last_name-help">Ingresa tus apellidos</small>
                    </div>
                </div>
                <br>
                <div class="flex gap-4">
                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="rut">RUT</label>
                        <InputText id="rut" v-model="formNewUser.rut" aria-describedby="rut-help" />
                        <small id="rut-help">Ingresa tu RUT</small>
                    </div>

                    <div class="flex flex-col gap-2 w-1/2">
                        <label for="date_of_birth">Fecha de nacimiento</label>
                        <DatePicker id="date_of_birth" v-model="formNewUser.date_of_birth" dateFormat="dd/mm/yy"
                            aria-describedby="date_of_birth-help" placeholder="dd/mm/yyyy" />
                        <small id="date_of_birth-help">Ingresa tu fecha de nacimiento</small>
                    </div>
                </div>
                <br>
                <div class="flex flex-col gap-2">
                    <label for="email">Email</label>
                    <InputText id="email" v-model="formNewUser.email" aria-describedby="email-help" />
                    <small id="email-help">Ingresa tu correo</small>
                </div>
                <br>
                <div class="flex flex-col gap-2">
                    <label for="password">Contraseña</label>
                    <Password id="password" v-model="formNewUser.password" aria-describedby="password-help"
                        class="p-password" promptLabel="Introduce tu contraseña" weakLabel="Débil" mediumLabel="Mediana"
                        strongLabel="Fuerte" />
                    <small id="password-help">Ingresa tu contraseña</small>
                </div>
                <br>
                <div class="flex flex-col gap-2">
                    <label for="is_active">Usuario Activo/Inactivo</label>
                    <ToggleSwitch v-model="formNewUser.is_active" />
                    <small id="is_active-help">{{ formNewUser.is_active ? 'Activado' : 'Inactivo' }}</small>
                </div>
            </div>

            <Divider :style="{ 'margin': '1rem 0px 0px 0px' }" />

            <template #footer>
                <Button label="Cancelar" class="p-button-text" @click="modalAddUser = false" />
                <Button label="Guardar" class="p-button-primary" @click="SaveUser()" />
            </template>
        </Dialog>
    </main>
</template>

<script>
export default {
    name: 'AdministradorsList',
    data() {
        return {
            administrators: [],
            loading: true,
            dictRol: {
                superadmin: 'Super Administrador',
                admin: 'Administrador'
            },
            modalAddUser: false,
            formNewUser: null,
        }
    },
    mounted() {
        this.LoadAdministrators();
    },
    methods: {
        async LoadAdministrators() {
            try {
                const { data } = await this.$axios.get('/administrators');
                    if(data.status) this.administrators = data.administrators.map(user => ({
                    ...user,
                    full_name: `${user.first_name} ${user.last_name}`,
                }));
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        async SaveUser() {
            try {
                const { data } = await this.$axios.post('/administrators/create', this.formNewUser);
                if (data.success) {
                    this.LoadAdministrators();
                    this.modalAddUser = false;
                    this.formNewUser = null;
                }
                console.log(data);
            } catch (error) {
                console.error(error);
            }
        },
        OpenModal() {
            this.modalAddUser = true;
            this.formNewUser = {};
        }
        /* getSeverity(status) {
            switch (status) {
                case 'unqualified':
                    return 'danger';
                case 'qualified':
                    return 'success';
                case 'new':
                    return 'info';
                case 'negotiation':
                    return 'warn';
                case 'renewal':
                    return null;
            }
        } */
    },
}
</script>

<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

::v-deep .p-password input {
    width: 100% !important;
}
</style>