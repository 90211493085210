<template>
  <div class="custom-panel">
    <div class="elements-options" v-if="$slots.options">
      <slot name="options"></slot>
    </div>
    <div class="custom-panel-content">
      <p class="mb5">{{ title }}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false, // Hacerlo opcional si no siempre se necesita un título
    },
  },
  inheritAttrs: false,
}
</script>

<style scoped>
.custom-panel {
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  margin-bottom: 20px;
  position: relative;
  padding: 10px;
}

.elements-options {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 1;
}

.custom-panel-content {
  padding: 10px;
  height: 110px;
}

.custom-panel:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Sombreado suave al pasar el mouse */
  border-color: #b0b0b0;
  /* Borde más oscuro al hacer hover */
}
</style>
