/* eslint-disable */
import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import { createPinia } from "pinia";
import { useAuthStore } from "./stores/auth";
import 'font-awesome/css/font-awesome.css';
import router from "./router";
import Modal from "./components/Modal.vue";
import CustomPanel from './components/CustomPanel.vue';
import CustomModal from './components/CustomModal.vue';
import utils from "./util.js";
import { createDevicePlugin } from './plugins/device'

import {
  primeVueComponents,
  primeVueDirectives,
  usePrimeVue,
} from "./plugins/primeVueConfig";

import "./assets/flags.css";
import "./assets/styles.css";
import "./assets/tailwind.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

const app = createApp(App);

const device = createDevicePlugin()
app.config.globalProperties.$device = device

const pinia = createPinia();
app.use(pinia);

const auth = useAuthStore();
auth.initializeUser();

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
});

async function refreshToken() {
  try {
    console.log(auth.refreshToken);
    const response = await axiosInstance.post("/auth/refresh", {
      token: auth.refreshToken,
    });

    auth.setToken(response.data.access_token);
    if (response.data.refresh_token) {
      auth.setRefreshToken(response.data.refresh_token);
    }

    auth.initializeUser();
    return response.data.access_token;
  } catch (error) {
    console.error("Error al refrescar el token", error);
    throw error;
  }
}

axiosInstance.interceptors.request.use(
  (config) => {
    if (auth.token) {
      config.headers["Authorization"] = `Bearer ${auth.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshToken();

        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Error al intentar refrescar el token:", refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

app.config.globalProperties.$axios = axiosInstance;

utils.setupGlobalUtils(app);

primeVueComponents.forEach((component) => {
  app.component(component.name, component);
});

primeVueDirectives.forEach(({ name, directive }) => {
  app.directive(name, directive);
});

app.component(Modal, Modal);
app.component('CustomPanel', CustomPanel);
app.component('CustomModal', CustomModal);
usePrimeVue(app);
app.use(router);
app.provide('device', device)

app.mount("#app");
